.notification-container-top-center,
.notification-container-top-left,
.notification-container-top-right,
.notification-container-bottom-center,
.notification-container-bottom-left,
.notification-container-bottom-right {
  width: 325px;
  position: absolute;
  pointer-events: all;
}

.notification-container-top-center {
  transform: translateX(-50%);
  top: 20px;
  left: 50%;
}

.notification-container-bottom-center {
  transform: translateX(-50%);
  bottom: 20px;
  left: 50%;
}

.notification-container-top-left {
  left: 20px;
  top: 20px;
}

.notification-container-top-right {
  right: 20px;
  top: 20px;
}

.notification-container-bottom-left {
  left: 20px;
  bottom: 20px;
}

.notification-container-bottom-right {
  bottom: 20px;
  right: 20px;
}

.notification-container-mobile-top,
.notification-container-mobile-bottom {
  pointer-events: all;
  position: absolute;
}

.notification-container-mobile-top {
  right: 20px;
  left: 20px;
  top: 20px;
}

.notification-container-mobile-bottom {
  right: 20px;
  left: 20px;
  bottom: 20px;
  margin-bottom: -15px;
}