@import "./_containers.scss";
@import "./_types.scss";

@keyframes timer {
  0% { width: 100%; }
  100% { width: 0%; }
}

.react-notification-root {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.notification-item {
  display: flex;
  position: relative;
  border-radius: 3px;
  margin-bottom: 15px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  .timer {
    width: 100%;
    height: 3px;
    margin-top: 10px;
    border-radius: 5px;

    .timer-filler {
      height: 3px;
      border-radius: 5px;
    }
  }

  .notification-title {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .notification-message {
    color: #fff;
    max-width: calc(100% - 15px);
    font-size: 14px;
    line-height: 150%;
    word-wrap: break-word;
    margin-bottom: 0;
    margin-top: 0;
  }

  .notification-content {
    padding: 8px 15px;
    display: inline-block;
    width: 100%;
  }

  .notification-close {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;

    &::after {
      content: '\D7';
      position: absolute;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 12px;
      left: 50%;
      top: 50%;
    }
  }
}

.n-parent { 
  width: 275px;
}

.notification-container-mobile-top .notification-item,
.notification-container-mobile-bottom .notification-item,
.notification-container-mobile-top .n-parent,
.notification-container-mobile-bottom .n-parent {
  max-width: 100%;
  width: 100%;
}

.notification-container-top-right .n-parent,
.notification-container-bottom-right .n-parent {
  margin-left: auto;
}

.notification-container-top-left .n-parent,
.notification-container-bottom-left .n-parent {
  margin-right: auto;
}

.notification-container-mobile-top .n-parent,
.notification-container-mobile-bottom .n-parent {
  margin-left: auto;
  margin-right: auto;
}